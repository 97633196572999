<script setup>
defineOptions({
    inheritAttrs: false,
});

defineProps({
    value: {
        type: [Boolean, String, Array],
        required: false,
    },
});

const model = defineModel();
</script>

<template>
    <div class="flex flex-row gap-2" data-slot="checkbox-input">
        <input
            class="#text-sm self-start rounded border-2 border-gray-200 bg-gray-50 font-medium text-gray-700 checked:bg-gray-700 focus:ring-3 focus:ring-blue-500/25 focus:ring-offset-2 focus:ring-offset-transparent data-[size=lg]:p-2 dark:border-gray-800 dark:bg-gray-950 dark:text-gray-300 dark:checked:bg-gray-800"
            type="checkbox"
            data-slot="input"
            :value="value"
            v-model="model"
            v-bind="$attrs"
        />
        <div class="flex flex-col gap-2">
            <slot />
        </div>
    </div>
</template>
